

















































































































































































import { GroupForm } from "@/types/helpers";
import Vue from "vue";
import Component from "vue-class-component";
import groupModule from "@/store/modules/groups";
import { Group } from "@/types/group";

@Component({
  components: {},
})
export default class GroupNew extends Vue {
  e6 = 1;
  group: GroupForm = {
    id: -1,
    name: "",
    description: "",
    picture: undefined,
    isPrivate: undefined,
  };
  errors = {};

  get imageUrl(): string | undefined {
    if (!this.group.picture) return "";
    return URL.createObjectURL(this.group.picture);
  }

  mounted(): void {
    this.group.name = (this.$route.query.text as string) ?? "";
  }

  async sendForm(): Promise<void> {
    const formData = new FormData();
    for (const [key, value] of Object.entries(this.group)) {
      if (value) formData.append(key, value);
    }

    try {
      this.errors = {};
      const group: Group = await groupModule.add(formData);
      this.$router.push({
        name: "Group",
        params: { group_id: group.id },
      });
      this.$toast.success(this.$t("groups.create.success").toString());
    } catch (err) {
      //console.log("err", err);
      this.errors = err.response.data.errors;
      this.$toast.error(this.$t("global.error-form").toString());
    }
  }
}
